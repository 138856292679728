<template>
    <Card title="Read Mail">
        <div>
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-sm btn-outline-secondary" @click="back">
                    <i class="fa fa-arrow-left"></i>
                </button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-danger btn-sm" @click="remove">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="First group" @click="getData">
                <button type="button" class="btn btn-outline-primary btn-sm">
                    <i class="fas fa-sync"></i>
                </button>
            </div>
        </div>
        <hr />
        <div v-if="message" class="mailbox-read-info">
            <h6 class="my-4">
                From: {{ message.name }} - <a :href="`mailto:${message.email}`">{{ message.email }}</a>
                <span class="float-end">{{ getDate(message.createAt.seconds * 1000) }}</span>
            </h6>
            <h5>{{ message.subject }}</h5>
        </div>
        <div v-if="message" class="mailbox-read-message my-3">
            <p>{{ message.text }}</p>
        </div>
    </Card>
</template>

<script>
import moment from 'moment'
import firebase from '../../../firebase'
import Card from '@/components/admin/Card.vue'
export default {
    components: { Card },
    data() {
        return {
            message: null,
        }
    },
    methods: {
        getData() {
            firebase.firestore().collection('messages').doc(this.$route.params.id).get().then((snapshot) => {
                this.message = snapshot.data()
                console.log(snapshot.data())
            })
        },
        remove() {
            firebase.firestore().collection('messages').doc(this.$route.params.id).delete().then(() => this.back()).catch(err => console.log(err))
        },
        back() {
            this.$router.push({ name: 'AdminInbox' })
        },
        getDate(milliseconds, format = 'LL - HH:MM') {
            if (!milliseconds)
                return '';
            return moment(milliseconds).utc().format(format);
        }
    },
    mounted() {
        firebase.firestore().collection('messages').doc(this.$route.params.id).onSnapshot((snapshot) => {
            this.message = snapshot.data()
            console.log(snapshot.data())
        })
    }
}
</script>

<style scoped>
.page-link {
    cursor: pointer;
}
</style>